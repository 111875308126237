<template>
  <div class="content">
    <van-tabs v-model="active" sticky @click="tabClick">
      <van-tab v-for="(tabItem,index) in orderTitle" :key="index" :title="tabItem.title">
        <van-list
          v-model="loading"
          :finished="finished"
          :error.sync="error"
          :immediate-check="false"
          offset="300"
          error-text="请求失败，点击重新加载"
          finished-text=""
          @load="onLoad"
        >
          <div v-if="isData" class="noDataWrap">
            <img
              class="noDataImg"
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-22/23/yuelvhui680UxFT6uf1608649312.png"
              alt=""
            >
            <p class="noDataText">您还没有相关订单</p>
          </div>
          <div v-else class="OrderListContent">
            <div v-for="(item,itemIndex) in orderList" :key="itemIndex" class="orderInfo">
              <div @click="goOrderDetail(item)">
                <div class="infoNameStatus">
                  <div class="infoName">
                    <p>{{ item.depCity }}</p>
                    <img
                      src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-24/22/yuelvhuiEe06qCRO2n1608818577.png"
                      alt=""
                    >
                    <p>{{ item.arrCity }}</p>
                  </div>
                  <div class="infoStatus">
                    <!--                                    25已取消 41出票中 42已出票 5待支付 46已退票 45退票中 40未出票-->
                    <p
                      v-if="item.orderStatus === 25 || item.orderStatus === 42 || item.orderStatus === 46 || item.orderStatus === 40"
                      style="color:#999999;"
                    >{{ item.orderStatusText }}</p>
                    <p v-if="item.orderStatus === 41 || item.orderStatus === 45" style="color:#E33F44;">
                      {{ item.orderStatusText }}</p>
                    <p v-if="item.orderStatus === 5" style="color:#EF8F49;">{{ item.orderStatusText }}</p>
                  </div>
                </div>
                <div class="infoTime">
                  <p>{{ item.depDate }}</p>
                  <p>{{ item.flightNo }} / {{ item.departureTerminal }}</p>
                </div>
                <div class="infoDate">
                  <p>{{ item.bookingTime }}</p>
                  <p>支付金额：{{ item.actualPrice }}</p>
                </div>
              </div>
              <div>
                <!--已出票改签和退票-->
                <div v-if="item.orderStatus === 5 || item.orderStatus === 41" class="PayBtnWrap">
                  <div class="PayBtnOne">
                    <p @click="CancelOrder(item)">取消订单</p>
                    <!--                                    <p @click="PayOrder(item)">付款</p>-->
                  </div>
                </div>
                <div v-else-if="item.orderStatus === 42" class="PayBtnWrap">
                  <div class="PayBtnTwo">
                    <p @click="ChangeOrder(item)">改签</p>
                    <p @click="RefundOrder(item)">退票</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import Vue from 'vue'
import { List, Tab, Tabs, Toast } from 'vant'

Vue.use(Tab).use(Tabs).use(Toast).use(List)
import {
  getOrderListNew,
  cancelOrder
} from '@/services/comeOn.js'

export default {
  name: 'TicketOrder',
  data() {
    return {
      mid: '1120465',
      page: 1,
      loading: false,
      finished: false,
      error: false,
      active: 0, // tab默认选中1
      isData: true,
      orderTitle: [
        {
          title: '全部',
          status: 0
        }, {
          title: '已出票',
          status: 2
        }, {
          title: '退改签',
          status: 3
        }
      ], // TitleList
      orderList: []
    }
  },
  created() {

  },
  mounted() {
    // this.mid = window.localStorage.getItem('uid');
    this.getOrderList(0)
  },
  methods: {
    tabClick(name, title) {
      this.name = name
      this.page = 1
      this.getOrderList(name)
    },
    // 滚动加载时触发，list组件定义的方法
    onLoad() {
      this.page++
      this.getOrderList(this.name)
    },
    getOrderList(status) {
      if (Number(status) === 1) {
        status = 42// 已出票
      } else if (Number(status) === 2) {
        status = 46// 退改签
      }
      getOrderListNew({
        page: this.page,
        pageSize: 10,
        orderType: 501, // 701,视频711手机
        mid: this.mid, // 1102762,
        status: status// 0,//默认0，代付款5充值中10，已完成20
      }).then(res => {
        if (Number(res.code) === 200) {
          if (res.data.length < 1 || !res.data) {
            this.isData = true
            this.finished = true
            return
          } else if (res.data.length < 10) {
            this.isData = false
            this.finished = true
            if (this.page > 1) {
              this.orderList = this.orderList.concat(res.data)
            } else {
              this.orderList = res.data
            }
            return
          } else {
            this.isData = false
            if (this.page > 1) {
              this.orderList = this.orderList.concat(res.data)
            } else {
              this.orderList = res.data
            }
          }
          this.loading = false
          this.refreshing = false
        } else {
          Toast(res.msg)
        }
      })
      this.isData = false
    },
    // go订单详情
    goOrderDetail(item) {
      this.$router.push({
        path: '/ticketOrderDetail',
        query: {
          orderNo: item.orderSubNo
        }
      })
    },
    // 取消订单
    CancelOrder(item) {
      cancelOrder({
        orderNo: item.orderNo
      }).then(res => {
        if (Number(res.code) === 200) {
          item.orderStatus = 25// 取消成功后状态为已取消
          Toast.success(res.msg)
        }
      })
    },
    // 付款
    PayOrder(item) {

    },
    // 改签
    ChangeOrder(item) {
      this.$router.push({
        path: '/ticketChange',
        query: {
          orderNo: item.orderSubNo
        }
      })
    },
    // 退票
    RefundOrder(item) {
      this.$router.push({
        path: '/ticketRefund',
        query: {
          orderNo: item.orderSubNo
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
}

.content {
  position: absolute;
  text-align: left;

  .OrderListContent {
    padding: 10px 13px;
    background-color: #fff;

    .orderInfo {
      width: 100%;
      padding: 13px 10px;
      background-color: #fff;
      border-radius: 7px;
      margin-bottom: 10px;

      .infoNameStatus {
        display: flex;

        .infoName {
          display: flex;

          p {
            font-size: 16px;
            color: #333333;
            font-weight: 500;
          }

          img {
            width: 15px;
            height: 5px;
            margin: 5px 10px;
          }
        }

        .infoStatus {
          margin-left: auto;
        }
      }

      .infoTime {
        margin-top: 5px;

        p {
          font-size: 12px;
          color: #999999;
          line-height: 20px;
        }
      }

      .infoDate {
        display: flex;
        margin-top: 5px;

        p:nth-child(1) {
          font-size: 12px;
          color: #999999;
        }

        p:nth-child(2) {
          font-size: 13px;
          color: #333333;
          font-weight: 400;
          margin-left: auto;
        }
      }
    }
  }

  .noDataWrap {
    text-align: center;
    margin-top: 150px;

    .noDataImg {
      width: 150px;
      height: 150px;
    }

    .noDataText {
      font-size: 14px;
      color: #ccc;
      margin-top: 20px;
    }
  }

  .PayBtnWrap {
    border-top: 1px solid #F2F2F2;
    padding: 13px 0px 0px;
    margin-top: 13px;
    overflow: hidden;

    .PayBtnOne, .PayBtnTwo {
      float: right;

      p {
        width: 85px;
        height: 30px;
        border-radius: 15px;
        font-size: 14px;
        line-height: 30px;
        text-align: center;
        float: left;
        border: 1px solid #969696;
        color: #999999;
        margin-right: 6px;
      }
    }

    .PayBtnOne {
      p:nth-child(2) {
        border: 1px solid #E43F44;
        color: #E43F44;
      }
    }
  }
}
</style>
